import LetterTile from "./LetterTile";

export default function WordBoard({ guess, word }) {
  

  function checkCss(letter, index) {
    const normal = "word-letter col";
    let css = normal 
    word.map((l) => {
      if (l === letter) {
        css = normal + " wrong-place"
      }
      return css
    })
    if (word[index].toString() === guess[index].toString()) {
      css = normal + " right-place";
    }
    return css
  }

  return (
    <div className="row gx-0">
      {guess &&
        guess.map((letter, index) => {
          return (
            <div className={checkCss(letter, index)} key={index}>
              <LetterTile letter={letter} onClick={() => console.log("click")} />
            </div>
          );
        })}
    </div>
  );
}
