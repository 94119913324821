// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.letter-board {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 0px;
  grid-gap: 0px;
}

.backspace {
  grid-column: 4 / span 4;
  border: 1px solid white;
  font-family: 'Courier New', Courier, monospace;
  padding-top: 15px;
}

.backspace:active {
  background-color: #662347;
  color: #b096a4;
  border: 1px solid #b096a4;
  transform: scale(.9);
}`, "",{"version":3,"sources":["webpack://./src/components/LetterBoard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,8CAA8C;EAC9C,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,yBAAyB;EACzB,oBAAoB;AACtB","sourcesContent":[".letter-board {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  grid-column-gap: 0px;\n  grid-gap: 0px;\n}\n\n.backspace {\n  grid-column: 4 / span 4;\n  border: 1px solid white;\n  font-family: 'Courier New', Courier, monospace;\n  padding-top: 15px;\n}\n\n.backspace:active {\n  background-color: #662347;\n  color: #b096a4;\n  border: 1px solid #b096a4;\n  transform: scale(.9);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
