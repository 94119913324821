import pb from "./pocketbase";
import getLetters from "./utils";

async function fetchDaily() {
  let gameData;
  try {
    const itemDate = `date="${new Date().toDateString()}"`;
    gameData = await pb.collection("dailygame").getFirstListItem(itemDate);
  } catch {
    const game = await pb.collection("games").getList(1, 1, {
      sort: "@random",
    });

    const data = game.items[0];

    const newGame = {
      date: new Date().toDateString(),
      game: {
        word: data.word.split(""),
        clue: data.clue,
        letters: getLetters(data.word),
      },
    };

    gameData = await pb.collection("dailygame").create(newGame);
  }
  return gameData;
}

export async function newGame(id, callBack) {
  try {
    await pb.collection("dailygame").delete(id);
  } catch {
    console.log('didnt work')
  }
  callBack()
}

export default fetchDaily;
