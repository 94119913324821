import "./LetterBoard.css";
import LetterTile from "./LetterTile";

export default function LetterBoard({ letters, onClick }) {
  return (
    <div>
      <div className="letter-board">
        {letters &&
          letters.map((letter, index) => {
            return (
              <div key={index}>
                <LetterTile letter={letter} onClick={() => onClick(letter)} />
              </div>
            );
          })}
      </div>
      <div>
        <LetterTile letter={"backspace"} onClick={() => onClick("backspace")} />
      </div>
    </div>
  );
}
