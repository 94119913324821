import "./LetterTile.css";

export default function LetterTile({ letter, onClick }) {
  return (
    <div
      className={letter === "backspace" ? "backspace" : "letter"}
      id={letter}
      onClick={(e) => onClick(e)}
    >
      <p id={letter}>{letter}</p>
    </div>
  );
}
