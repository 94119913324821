// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clue {
    color: #662347;
    background-color: white;
    margin: 20px 0px;
    padding: 30px;
    border-radius: 5px;

}`, "",{"version":3,"sources":["webpack://./src/components/Clue.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;IACvB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;;AAEtB","sourcesContent":[".clue {\n    color: #662347;\n    background-color: white;\n    margin: 20px 0px;\n    padding: 30px;\n    border-radius: 5px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
