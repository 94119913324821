import "./App.css";
import DailyGame from "./components/DailyGame";
import fetchDaily from "./lib/fetch";

let gameData = await fetchDaily();

console.log(gameData.id);

function App() {
  return (
    <div className="container col-lg-4 col-md-8 col-sm-12 justify-content-center">
      <div className="App text-light">
        <h1 className="title">Addictionary</h1>
        <DailyGame gameData={gameData} />
      </div>
    </div>
  );
}

export default App;
