function padRandomLetters(word) {
  const chars = [...new Set(word.split(""))];

  while (chars.length < 16) {
    const randomChar = String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    if (!chars.includes(randomChar)) {
      chars.push(randomChar);
    }
  }
  return chars;
}

function scrambleWord(word) {
  return word.sort(() => 0.5 - Math.random());
}

export default function getLetters(word) {
  const paddedWord = padRandomLetters(word);
  return scrambleWord(paddedWord);
}
