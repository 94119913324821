// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.letter {
  border: 1px solid white;
  aspect-ratio: 1/1;
  border-radius: 5%;
}

.letter:active {
  background-color: #662347;
  color: #b096a4;
  border: 1px solid #b096a4;
  transform: scale(0.9);
}
.letter p {
  position: relative;
  top: 40%;
  font-family: "Courier New", Courier, monospace;
}

.word-letter p {
  top: 34%;
}

.wrong-place p {
  color: yellow;
  animation-name: wrong-place;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.right-place {
  color: #39ff14;
  animation-name: right-place;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes wrong-place {
  from {
    font-size: 1em;

    color: #ffbf00;
  }
  to {

    font-size: 1.1em;
    color: yellow;
  }
}

@keyframes right-place {
  from {
    font-size: 1em;
    border: 1px solid white;
    border-radius: 5%;
    color: #046c34;
  }
  to {
    font-size: 1.1em;
    border: 1px solid grey;
    border-radius: 5%;
    color: #39ff14;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LetterTile.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,8CAA8C;AAChD;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;EACtB,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,sBAAsB;EACtB,mCAAmC;AACrC;;AAEA;EACE;IACE,cAAc;;IAEd,cAAc;EAChB;EACA;;IAEE,gBAAgB;IAChB,aAAa;EACf;AACF;;AAEA;EACE;IACE,cAAc;IACd,uBAAuB;IACvB,iBAAiB;IACjB,cAAc;EAChB;EACA;IACE,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;IACjB,cAAc;EAChB;AACF","sourcesContent":[".letter {\n  border: 1px solid white;\n  aspect-ratio: 1/1;\n  border-radius: 5%;\n}\n\n.letter:active {\n  background-color: #662347;\n  color: #b096a4;\n  border: 1px solid #b096a4;\n  transform: scale(0.9);\n}\n.letter p {\n  position: relative;\n  top: 40%;\n  font-family: \"Courier New\", Courier, monospace;\n}\n\n.word-letter p {\n  top: 34%;\n}\n\n.wrong-place p {\n  color: yellow;\n  animation-name: wrong-place;\n  animation-duration: 1s;\n  animation-iteration-count: infinite;\n}\n\n.right-place {\n  color: #39ff14;\n  animation-name: right-place;\n  animation-duration: 1s;\n  animation-iteration-count: infinite;\n}\n\n@keyframes wrong-place {\n  from {\n    font-size: 1em;\n\n    color: #ffbf00;\n  }\n  to {\n\n    font-size: 1.1em;\n    color: yellow;\n  }\n}\n\n@keyframes right-place {\n  from {\n    font-size: 1em;\n    border: 1px solid white;\n    border-radius: 5%;\n    color: #046c34;\n  }\n  to {\n    font-size: 1.1em;\n    border: 1px solid grey;\n    border-radius: 5%;\n    color: #39ff14;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
