import "./DailyGame.css";
import useLocalStorageState from "use-local-storage-state";
import WordBoard from "./WordBoard";
import Clue from "./Clue";
import LetterBoard from "./LetterBoard";
import Replay from "./Replay";

export default function DailyGame({ gameData }) {
  const game = gameData.game;
  const gameId = gameData.id;
  const word = game.word;
  const clue = game.clue
  const emptyGuess = Array(word.length).fill("?");
  const [guess, setGuess] = useLocalStorageState(gameId + "_guess", {
    defaultValue: emptyGuess,
  });
  const [count, setCount] = useLocalStorageState(gameId + "_count", {
    defaultValue: 0,
  });
  const [solved, setSolved] = useLocalStorageState(gameId, {
    defaultValue: false,
  });
  const [replayLog, setReplayLog] = useLocalStorageState(gameId + "_replay", {
    defaultValue: [],
  });

  function handleClick(e) {
    setReplayLog(replayLog ? [...replayLog, e] : []);
    let newGuess = [...guess];
    if (e !== "backspace" && guess.includes("?")) {
      newGuess[count] = e;
      setCount(() => count + 1);
      setGuess([...newGuess]);
    }
    if (e === "backspace" && count > 0 && guess !== emptyGuess) {
      setCount(() => count - 1);
      newGuess[count - 1] = "?";
      setGuess([...newGuess]);
    }
  }

  if (guess.join("") === word.join("") && !solved) {
    setSolved(() => true);
  }

  return (
    <div className="daily-game">
      {game && (
        <div className="justify-content-center">
          <h6>Daily Game - {gameData.date} </h6>
          {solved ? (
            <>
              <Replay replayLog={replayLog} word={word} />
              <Clue clue={`Yes, the word is ${game.word.join("")}; ${clue}`} />
              <p>Come back again tomorrow for a new word!</p>
            </>
          ) : (
            <>
              <WordBoard guess={guess} word={game.word} />

              <Clue clue={clue} />
              <LetterBoard letters={game.letters} onClick={handleClick} />
            </>
          )}
        </div>
      )}
    </div>
  );
}
