import { useState } from "react";
import WordBoard from "./WordBoard";

export default function Replay({ replayLog, word }) {
  const emptyGuess = Array(word.length).fill("?");
  const [guess, setGuess] = useState(emptyGuess);
  const [played, setPlayed] = useState(false);

  function replay(e) {
    if (e === "backspace") {
      setGuess((prevGuess) => {
        const updatedGuess = prevGuess.slice(
          0,
          -(prevGuess.filter((c) => c === "?").length + 1)
        );

        const numMissing = word.length - updatedGuess.length;
        return [
          ...updatedGuess,
          ..."?".repeat(numMissing > 0 ? numMissing : 0),
        ];
      });
      return;
    } else {
      setGuess((prevGuess) => {
        return prevGuess.join("").replace("?", e, 1).split("");
      });
    }
  }

  if (!played) {
    replayLog.map((e, i) => {
      return setTimeout(() => {
        replay(e);
      }, 250 * (i + 1));
    });
    setPlayed(true);
  }

  return (
    <div className="text-green-500">
      <WordBoard guess={guess} word={word} />
    </div>
  );
}
