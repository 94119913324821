// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daily-game {
  margin: 0;
  padding: 0;
  text-align: center;
}

.new-game:active {
  color: whitesmoke;
}
`, "",{"version":3,"sources":["webpack://./src/components/DailyGame.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".daily-game {\n  margin: 0;\n  padding: 0;\n  text-align: center;\n}\n\n.new-game:active {\n  color: whitesmoke;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
