import './Clue.css'

export default function Clue({clue}) {
  return (
    <div>
        <p className="clue">
            {clue}
        </p>
    </div>
  )
}
